import React, { useEffect, useContext, useState } from 'react';
import { Card, Typography, Button, Space, Avatar, message, Layout, Affix, Row, Col, Image } from 'antd';
import { QqOutlined, SendOutlined, CopyOutlined, HomeOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Context } from './store/Context';

const { Title, Text, Paragraph } = Typography;

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function getLanguage() {
  return navigator.language || navigator.userLanguage;
}

function isChineseLang(lang) {
  return lang.startsWith('zh-CN') || lang.startsWith('zh-Hans');
}

function getUrlParams() {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    autoRedirect: searchParams.get('redirect') === 'true',
    platform: searchParams.get('platform'),
    delay: parseInt(searchParams.get('delay')) || 5000,
  };
}

const ContactCard = () => {
  const lang = getLanguage();
  const isChinese = isChineseLang(lang);
  const urlParams = getUrlParams();
  const [countdown, setCountdown] = useState(Math.floor(urlParams.delay / 1000));

  const contactInfo = {
    qq: {
      number: '99365587',
      title: isChinese ? 'QQ群' : 'QQ Group',
      mobile: "https://qm.qq.com/cgi-bin/qm/qr?k=zbtEihPmusMbwnCjUAFgURn1VXGk8ZJd&jump_from=webapi&authKey=ddEfeFbqdQHKoAjMms7w2KbVNOWlxPYCHm845pZsAr58Bdit3nefcw34NJ8UnHHl",
      desktop: 'https://qm.qq.com/cgi-bin/qm/qr?k=yOXe4KgYCV22hE1naR31NEWNidsC47No&jump_from=webapi&authKey=2ykLnyMxJZXziBQnN3CfWf3RSlB6JsHROMjfcupVjJ97X2opWEYBN2nItOJ24NrA',
    },
    telegram: {
      username: '@UNDSClub',
      title: isChinese ? 'Telegram频道' : 'Telegram Channel',
      mobile: 'tg://resolve?domain=UNDSClub',
      desktop: 'https://t.me/undsClub',
    },
  };

  const { state } = useContext(Context);

  useEffect(() => {
    if (urlParams.autoRedirect) {
      // 倒计时效果
      const countdownInterval = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
          }
          return prev - 1;
        });
      }, 1000);

      // 跳转计时器
      const redirectTimer = setTimeout(() => {
        const platform = urlParams.platform || (isChinese ? 'qq' : 'telegram');
        if (contactInfo[platform]) {
          const url = isMobile ? contactInfo[platform].mobile : contactInfo[platform].desktop;
          window.location.href = url;
        }
      }, urlParams.delay);

      return () => {
        clearInterval(countdownInterval);
        clearTimeout(redirectTimer);
      };
    }
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success(isChinese ? '已复制' : 'Copied');
  };

  const redirect = (platform) => {
    const url = isMobile ? contactInfo[platform].mobile : contactInfo[platform].desktop;
    window.location.href = url;
  };

  const createContactCards = () => {
    const qqCard = (
      <Card
        hoverable
        style={{ borderRadius: '10px' }}
        actions={[
          <Button key="copy" type="link" icon={<CopyOutlined />} onClick={() => copyToClipboard(contactInfo.qq.number)}>
            {isChinese ? '复制群号' : 'Copy Number'}
          </Button>,
          <Button key="join" type="primary" icon={<ArrowRightOutlined />} onClick={() => redirect('qq')}>
            {isChinese ? '加入群聊' : 'Join Group'}
          </Button>,
        ]}
      >
        <Space size="large">
          <Avatar size={64} icon={<QqOutlined />} style={{ background: 'linear-gradient(135deg, #00d2ff 0%, #3a7bd5 100%)' }} />
          <div>
            <Title level={4} style={{ margin: 0 }}>{contactInfo.qq.title}</Title>
            <Paragraph type="secondary" style={{ margin: 0 }}>{isChinese ? '群号：' : 'Group: '}{contactInfo.qq.number}</Paragraph>
            <Text type="secondary">{isChinese ? '加入玩家社群' : 'Join Player Community'}</Text>
          </div>
        </Space>
      </Card>
    );

    const telegramCard = (
      <Card
        hoverable
        style={{ borderRadius: '10px' }}
        actions={[
          <Button key="copy" type="link" icon={<CopyOutlined />} onClick={() => copyToClipboard(contactInfo.telegram.username)}>
            {isChinese ? '复制频道名' : 'Copy Channel'}
          </Button>,
          <Button key="join" type="primary" icon={<ArrowRightOutlined />} onClick={() => redirect('telegram')}>
            {isChinese ? '加入频道' : 'Join Channel'}
          </Button>,
        ]}
      >
        <Space size="large">
          <Avatar size={64} icon={<SendOutlined />} style={{ background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)' }} />
          <div>
            <Title level={4} style={{ margin: 0 }}>{contactInfo.telegram.title}</Title>
            <Paragraph type="secondary" style={{ margin: 0 }}>{contactInfo.telegram.username}</Paragraph>
            <Text type="secondary">{isChinese ? '一起来玩吧' : "Let's Play Together"}</Text>
          </div>
        </Space>
      </Card>
    );

    return isChinese ? [qqCard, telegramCard] : [telegramCard, qqCard];
  };

  return (
    <Layout style={{ minWidth: '100vw', minHeight: '100vh', background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)' }}>
      <Affix offsetTop={0}>
        <Layout.Header style={{ background: 'rgba(255, 255, 255, 0.8)', height: '70px', backdropFilter: 'blur(10px)' }}>
          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
            <Col style={{ cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}>
              <Space>
                <Image
                  height={isMobile ? 24 : 30}
                  preview={false}
                  src={state.appLogo}
                  style={{
                    objectFit: 'contain',
                    width: 'auto',
                    maxHeight: '100%'
                  }}
                />
                <Typography.Title level={3} style={{ margin: 0 }}>{state.appName}</Typography.Title>
              </Space>
            </Col>
          </Row>
        </Layout.Header>
      </Affix>

      <Layout.Content style={{ padding: '40px 20px' }}>
        <Row justify="center">
          <Col xs={24} sm={20} md={16} lg={12}>
            <Card style={{ borderRadius: '10px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
              <Title level={3} style={{ textAlign: 'center', marginBottom: '40px' }}>
                {isChinese ? '选择联系方式' : 'Choose Contact Method'}
              </Title>
              {urlParams.autoRedirect && countdown > 0 && (
                <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                  <Typography.Text type="secondary" style={{ padding: '10px 20px', borderRadius: '20px', background: 'rgba(0, 0, 0, 0.05)' }}>
                    {isChinese
                      ? `${countdown}秒后将自动跳转到${urlParams.platform ? (urlParams.platform === 'qq' ? 'QQ群' : 'Telegram频道') : '推荐的联系方式'}`
                      : `Will redirect to ${urlParams.platform ? (urlParams.platform === 'qq' ? 'QQ Group' : 'Telegram Channel') : 'recommended platform'} in ${countdown} seconds`}
                  </Typography.Text>
                </div>
              )}

              <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {createContactCards().map((card, index) => (
                  <React.Fragment key={index}>{card}</React.Fragment>
                ))}

                <Button type="text" icon={<HomeOutlined />} onClick={() => window.location.href = '/'} style={{ marginTop: '20px' }}>
                  {isChinese ? '回到首页' : 'Back to Home'}
                </Button>
              </Space>
            </Card>
          </Col>
        </Row>
      </Layout.Content>

      <Layout.Footer style={{ textAlign: 'center', background: 'transparent' }}>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {state.appName} © {new Date().getFullYear()}
        </Typography.Text>
      </Layout.Footer>
    </Layout>
  );
};

export default ContactCard;
