import {
    Typography,
    Layout,
    Row,
    Col,
    Affix,
    Image,
    Button,
    Tooltip,
    Popover,
} from 'antd'

import {
    DownloadOutlined,
} from '@ant-design/icons'

import {
    motion,
} from 'framer-motion'

import {
    useContext,
} from 'react'

import { Context } from './store/Context'

// props: sectionItem, backgroundColor
function SectionItem(props) {
    return (
        <Row justify='center' align='top' style={{
            background: props.backgroundColor,
            padding: '48px 16px'
        }}>
            <Col span={24} style={{maxWidth: '680px'}}>
                <motion.div 
                    initial={{y: 20, opacity: 0}} 
                    whileInView={{y: 0, opacity: 1}}
                    transition={{duration: 0.6}}
                    viewport={{once: true}}
                >
                    <Typography.Title level={2} style={{
                        fontSize: '28px',
                        marginBottom: '16px',
                        textAlign: 'center'
                    }}>
                        {props.sectionItem.title}
                    </Typography.Title>
                    <Typography.Paragraph style={{
                        fontSize: '17px',
                        color: '#666',
                        textAlign: 'center',
                        marginBottom: '32px'
                    }}>
                        {props.sectionItem.text}
                    </Typography.Paragraph>
                    <Image 
                        preview={false} 
                        src={props.sectionItem.image} 
                        style={{
                            width: '100%',
                            borderRadius: '12px',
                            boxShadow: '0 1px 5px rgba(0,0,0,0.1)'
                        }}
                    />
                </motion.div>
            </Col>
        </Row>
    )
}

// props: sectionList
function SectionList(props) {
    return (
        <>
        {
            props.sectionList.map((sectionItem, index) => {
                return (
                    <SectionItem sectionItem={sectionItem} backgroundColor={index % 2 === 0? 'white': null} />    
                )

            })
        }
        </>
    )
}

function Mobile() {
    const {state, dispatch} = useContext(Context)

    return (
        <Layout style={{'overflow-x': 'hidden'}}>
            <Affix offsetTop={0}>
                <Layout.Header style={{
                    background: 'rgba(255,255,255,0.98)', 
                    backdropFilter: 'blur(20px)',
                    borderBottom: '1px solid #e6e6e6',
                    padding: '0 16px',
                    height: '48px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Row justify='space-between' align='middle' style={{
                        height: '100%',
                        width: '100%'
                    }}>
                        <Col>
                            <Typography.Title level={4} style={{
                                margin: 0,
                                fontSize: '17px',
                                fontWeight: '600',
                                lineHeight: '1',
                                display: 'flex',
                                alignItems: 'center',
                                height: '32px'
                            }}>
                                {state.appName}
                            </Typography.Title>
                        </Col>
                        <Col>
                            <Button type="primary" 
                                shape="round"
                                style={{
                                    background: '#0066CC',
                                    border: 'none',
                                    fontWeight: '500',
                                    padding: '0px 16px',
                                    height: '32px',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={() => { window.scrollTo(0, 0)}}
                            >
                                Download
                            </Button>
                        </Col>
                    </Row>
                </Layout.Header>
            </Affix>

            <Layout.Content>
                {/* cover headline */}
                <Row justify='center' align='top' style={{
                    padding: '24px 16px',
                    background: 'white'
                }}>
                    <Col span={24} style={{maxWidth: '680px'}}>
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <Row gutter={16} align="middle">
                                    <Col flex="64px">
                                        <Image 
                                            width={64}
                                            height={64}
                                            preview={false} 
                                            src={state.appLogo}
                                            style={{
                                                borderRadius: '12px',
                                                objectFit: 'cover',
                                                boxShadow: '0 1px 5px rgba(0,0,0,0.1)'
                                            }}
                                        />
                                    </Col>
                                    <Col flex="auto">
                                        <Typography.Title level={1} style={{
                                            fontSize: '34px',
                                            lineHeight: 1.2,
                                            margin: 0,
                                            marginBottom: '12px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            {state.appName}
                                        </Typography.Title>
                                        <Row align="middle">
                                            {state.appleStoreLink && (
                                                <Col>
                                                    <a href={state.appleStoreLink} target='_blank' rel="noopener noreferrer">
                                                        <Button type="primary" 
                                                            shape="round"
                                                            size='large'
                                                            style={{
                                                                background: '#0066CC',
                                                                border: 'none',
                                                                fontWeight: '500',
                                                                height: '44px',
                                                                padding: '0 24px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            Download
                                                        </Button>
                                                    </a>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col span={24}>
                                <Typography.Paragraph style={{
                                    fontSize: '17px',
                                    color: '#666',
                                    marginBottom: '16px',
                                    lineHeight: 1.5
                                }}>
                                    {state.coverText}
                                </Typography.Paragraph>
                            </Col>

                            {/* <Col span={24}>
                                <Image 
                                    preview={false} 
                                    src={state.coverImage}
                                    style={{
                                        width: '100%',
                                        borderRadius: '12px',
                                        boxShadow: '0 1px 5px rgba(0,0,0,0.1)'
                                    }}
                                />
                            </Col> */}
                        </Row>
                    </Col>
                </Row>

                {/* endorsement list */}
                <Row justify='center' style={{'marginTop': '50px', 'padding': '0 20px'}}>
                    {
                        state.endorsementList.map((endorsementItem, index) => {
                        return (
                            <Col key={index} style={{'margin': '10px'}}>
                            <Tooltip placement='top' title={endorsementItem.title} color={endorsementItem.titleColor}>
                                <a href={endorsementItem.URL} target='_blank' rel="noopener noreferrer">
                                <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                                    <Image 
                                    width={120} 
                                    height={60} 
                                    preview={false} 
                                    src={endorsementItem.image} 
                                    style={{
                                        'objectFit': 'contain',
                                        'boxShadow': '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                                    }}
                                    />
                                </div>
                                </a>
                            </Tooltip>
                            </Col>
                        )
                        })
                    }
                </Row>

                {/* section list */}
                <SectionList sectionList={state.sectionList} />

                {/* policies */}
                <Row justify='center' align='top' style={{'backgroundColor': 'white', 'height': '450px', 'padding': '70px 20px'}}>
                
                    <Row justify='center' align='top' style={{'width': '100%'}}>
                        <Button type="primary" shape="round" icon={<DownloadOutlined />} size='large' danger onClick={() => { window.scrollTo(0, 0)}}>Download</Button>
                    </Row>

                    <Row justify='space-around' align='top' style={{'width': '100%'}}>
                        <Col>
                            <Row justify='start' align='middle'>
                                <Typography>
                                 Policy
                                </Typography>
                            </Row>
                            <Row justify='start' align='middle' style={{'marginTop': '20px'}}>
                            <a href={state.appURL + '/policy/payment'} target='_blank' rel="noopener noreferrer">
                                <Typography.Title level={5} style={{'margin': '0px'}}>
                                    Payment Policy
                                </Typography.Title>
                            </a>
                            </Row>
                            <Row justify='start' align='middle' style={{'marginTop': '20px'}}>
                            <a href={state.appURL + '/policy/privacy'} target='_blank' rel="noopener noreferrer">
                                <Typography.Title level={5} style={{'margin': '0px'}}>
                                    Privacy Policy
                                </Typography.Title>
                            </a>
                            </Row>
                            <Row justify='start' align='middle' style={{'marginTop': '20px'}}>
                            <a href={state.appURL + '/policy/terms'} target='_blank' rel="noopener noreferrer">
                                <Typography.Title level={5} style={{'margin': '0px'}}>
                                    Terms of Service
                                </Typography.Title>
                            </a>
                            </Row>
                        </Col>


                        <Col >
                        
                        <Row justify='start' align='middle'>
                        <a href='/faq' target='_blank' rel="noopener noreferrer">
                            <Typography.Title level={5} style={{'cursor': 'pointer'}}>
                            FAQ
                            </Typography.Title>
                        </a>
                        </Row>
                        
                            <Row justify='start' align='middle'>
                                <Typography>
                                    Need help?
                                </Typography>
                            </Row>
                            <Row justify='start' align='middle' style={{'marginTop': '20px'}}>
                            <a href={'/contact'} target='_blank' rel="noopener noreferrer">
                                <Typography.Title level={5} style={{'margin': '0px', 'cursor': 'pointer'}}>
                                Contact Us
                                </Typography.Title>
                            </a>
                            </Row>
                            
                        </Col>

                    </Row>

                </Row>
                

                <Row justify="center" align='middle' style={{'backgroundColor': 'white', 'padding': '0 0 40px 0'}}>
                    <Col>
                        <Typography.Text type="secondary" style={{'fontSize': 12}}>
                            {state.appName} © {new Date().getFullYear()}
                        </Typography.Text>
                    </Col>
                </Row>

            </Layout.Content>

            {/*
            <Layout.Footer>


            </Layout.Footer>
            */}

            </Layout>
    )
}

export default Mobile
