import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBycNmXiiZqSq1VE1TME0KM9kYUremeq_I",
  authDomain: "unds-apple.firebaseapp.com",
  projectId: "unds-apple",
  storageBucket: "unds-apple.firebasestorage.app",
  messagingSenderId: "851592788894",
  appId: "1:851592788894:web:115c0c5319d24d8b39348f",
  measurementId: "G-BSQCR28L77"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };